import '@fontsource/public-sans';
import { useState, useRef } from 'react';
import Lottie from 'lottie-react';
import { CssVarsProvider } from '@mui/joy/styles';
import {
  Button,
  Typography,
  CssBaseline,
  Stack,
  Modal,
  ModalDialog,
  List,
  ListItemDecorator,
  ListItem,
  ListDivider,
  LinearProgress,
  Card,
  Sheet,
  Box,
  Input,
  Divider,
  ModalClose,
  Alert
} from '@mui/joy';
import { Send, Info } from '@mui/icons-material';
import Typewriter from 'typewriter-effect';
import { getResponse } from './modules/firebase.js';
import bgImg from './images/cute-kitty.jpg';
import cat from './lottie/cat.json';

const App = () => {
  const [messages, setMessages] = useState([
    {
      role: 'system',
      content:
        'You are an experienced vet. You are friendly, thoughtful, smart, and empathetic. Answer all questions as this persona.'
    }
  ]);
  const [question, setQuestion] = useState('');
  const [querying, setQuerying] = useState(false);
  const [answering, setAnswering] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const chatRef = useRef();
  const inputRef = useRef();

  const messageHistory = () => {
    if (messages.length === 1) {
      return null;
    }
    const formattedMessages = [];

    for (const [i, message] of messages.entries()) {
      if (message.role === 'assistant') {
        formattedMessages.push(
          <ListItem key={i}>
            <ListItemDecorator>😸</ListItemDecorator>{' '}
            {i + 1 === messages.length ? (
              <Typewriter
                onInit={typewriter => {
                  typewriter
                    .changeDelay(33)
                    .typeString(message.content)
                    .callFunction(() => {
                      setAnswering(false);
                      setQuestion('');
                      inputRef.current.focus();
                      chatRef.current.lastElementChild.scrollIntoView({
                        behavior: 'smooth'
                      });
                    })
                    .start();
                }}
              />
            ) : (
              message.content
            )}
          </ListItem>
        );
      } else if (message.role === 'user') {
        formattedMessages.push(
          <ListItem key={i}>
            <ListItemDecorator>👤</ListItemDecorator> {message.content}
          </ListItem>
        );
      }
      formattedMessages.push(<ListDivider key={`divider-${i}`} />);
    }

    if (querying) {
      formattedMessages.push(
        <Box key={'thinking'}>
          <LinearProgress sx={{ my: 3 }} />
        </Box>
      );
    }

    return formattedMessages;
  };

  const submitComment = async () => {
    setQuerying(true);

    const userQuestion = { role: 'user', content: question };
    setMessages([...messages, userQuestion]);
    const response = await getResponse([...messages, userQuestion]);
    setQuerying(false);
    setMessages([...messages, userQuestion, response.data]);
    setAnswering(true);
  };

  return (
    <CssVarsProvider>
      <CssBaseline />
      <Sheet
        sx={theme => ({
          position: 'fixed',
          width: '100%',
          p: 1,
          zIndex: 1,
          background: theme.vars.palette.primary.softActiveBg
        })}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography level="h5" textColor="common.white">
            🐈 AI Vet
          </Typography>
          <Button
            variant="soft"
            size="sm"
            startDecorator={<Info />}
            onClick={() => setShowModal(true)}
          >
            About
          </Button>
        </Stack>
      </Sheet>
      <Box
        justifyContent="center"
        display="flex"
        pt={3}
        pb={11}
        sx={{
          backgroundImage: `url('${bgImg}')`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          minHeight: '100vh'
        }}
      >
        {messages.length === 1 ? (
          <Box sx={{ mt: '33vh' }}>
            <Alert size="lg">Talk to an experienced vet about anything.</Alert>
          </Box>
        ) : (
          <Card
            variant="outlined"
            sx={{
              width: '88%',
              maxWidth: 555,
              background: 'rgba(255,255,255,0.88)',
              mt: 8
            }}
          >
            <Box sx={{ flex: 1 }}>
              <List ref={chatRef}>{messageHistory()}</List>
            </Box>
          </Card>
        )}
      </Box>
      <Sheet
        sx={{
          position: 'sticky',
          bottom: 0,
          display: 'flex',
          p: 1
        }}
      >
        <Input
          variant="plain"
          size="sm"
          slotProps={{
            input: {
              ref: inputRef
            }
          }}
          placeholder="Say anything…"
          autoFocus
          disabled={answering || querying}
          value={question}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              submitComment();
            }
          }}
          onChange={event => setQuestion(event.target.value)}
          sx={{ flexGrow: 1, mr: 1, '--Input-focusedThickness': '0px' }}
        />
        <Button
          variant="soft"
          size="sm"
          loading={answering || querying}
          onClick={() => submitComment()}
          disabled={question === ''}
          endDecorator={<Send />}
        ></Button>
      </Sheet>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <ModalDialog
          layout="fullscreen"
          size="lg"
          variant="outlined"
          sx={{ overflow: 'scroll' }}
        >
          <ModalClose />
          <Box>
            <Typography level="h6" gutterBottom fontWeight="bold">
              Conversations are private
            </Typography>
            <Typography level="body1" gutterBottom>
              This app is private and safe to use. All interactions are
              anonymous and cannot be traced back to you. All traffic is
              encrypted to our server.
            </Typography>
            <Typography level="h6" gutterBottom fontWeight="bold">
              Disclaimer
            </Typography>
            <Typography level="body1" gutterBottom>
              This is intended to be for entertainment purposes only. Although
              we hope you find it fun and useful too 😊
            </Typography>
            <Divider sx={{ my: 3 }} />
            <Lottie animationData={cat} loop={true} />
            <Button
              size="sm"
              href="https://golightlyplus.com"
              component="a"
              variant="plain"
              target="_blank"
            >
              © Golightly+ {new Date().getFullYear()}
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </CssVarsProvider>
  );
};

export default App;
